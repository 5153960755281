<template>
  <div class="add-category">
    <div class="title">{{ title }}</div>
    <div class="cell">
      <el-form
        size="mini"
        style="width: 500px"
        ref="form"
        :rules="rules"
        :model="form"
        label-width="120px"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="分类图标">
          <Upload :imageUrl="imgList" :ids="ids" :urlList="urlList"></Upload>
        </el-form-item>
        <el-form-item label="所属父级">
          <el-cascader
            style="width: 100%"
            :show-all-levels="false"
            :props="props"
            v-model="form.pid"
            :options="options"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="最高销售价格">
          <el-input type="number" v-model="form.sales_price"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.weigh"></el-input>
        </el-form-item>
        <el-form-item label="是否热门">
          <el-radio-group v-model="form.is_hot">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
          <el-button @click="handleBack" plain>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import Upload from "@/components/AdminUpload";
import { categoryList, categoryAdd, categoryEdit } from "@/api/admin.js";
export default {
  components: {
    Upload,
  },
  data() {
    return {
      form: {
        id: 0,
        name: "",
        pid: 0,
        file_id: "",
        icon: "",
        weigh: "",
        status: 1,
        sales_price: "",
        is_hot: 1,
      },
      options: [],
      props: {
        value: "category_id",
        label: "name",
        checkStrictly: true,
        emitPath: false,
      },
      rules: {
        is_menu: [
          { required: true, message: "请选择功能类型", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
      },
      imgList: [],
      urlList: [],
      ids: [],
      title: "新增",
    };
  },
  created() {
    this.getNodeList();
    if (sessionStorage.ADMIN_CATEGORY_INFO) {
      this.title = "编辑";
      this.form = JSON.parse(sessionStorage.ADMIN_CATEGORY_INFO);
      if (this.form.image) {
        this.imgList.push(this.form.image);
        this.urlList.push(this.form.icon);
        this.ids.push(this.form.file_id);
      }

      delete this.form.list;
      delete this.form.image;
    }
  },
  methods: {
    getNodeList() {
      categoryList({
        status: "",
      }).then((res) => {
        if (res.code == 1) {
          this.options = res.data;
          this.options.unshift({
            name: "一级分类",
            category_id: 0,
          });
          this.formattingData(this.options);
        }
      });
    },
    // 格式化数据
    formattingData(arr) {
      arr.forEach((item) => {
        if (item.list && item.list.length != 0) {
          this.formattingData(item.list);
        } else {
          delete item.list;
        }
      });
    },
    onSubmit() {
      this.form.file_id = this.ids.join(",");
      this.form.icon = this.urlList.join(",");
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id == 0) {
            categoryAdd(this.form).then((res) => {
              if (res.code == 1) {

                this.$message.success("添加菜单成功");
                this.handleBack();
              }
            });
          } else {
            categoryEdit(this.form).then((res) => {
              if (res.code == 1) {
           
                this.$message.success("编辑菜单成功");
                this.handleBack();
              }
            });
          }
        }
      });
    },
    handleBack() {
      this.$router.replace("adminCategory");
    },
    handleChange() {},
  },
};
</script>
<style lang="less">
.add-category {
  .title {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .cell {
    background: #fff;
    padding: 20px;
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-radio__label {
  font-size: 12px;
}
</style>